@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(
    177.48deg,
    rgb(242, 243, 255) 18.87%,
    rgba(251, 248, 245, 0.43) 46.53%,
    rgba(251, 248, 245, 0.43) 46.53%,
    rgba(255, 250, 241, 0.19) 85%,
    rgb(242, 243, 255) 93%
  );
  margin: 0;
  background-color: #f8f8ff;
  font-family: "Karla";
  font-style: normal;
  font-size: 16px;
}

.ivry-font {
  font-family: "Rubik";
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.error {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.bouncing-loader > div {
  width: 2.5rem;
  height: 2.5rem;
  margin: 3px 10px;
  border-radius: 50%;
  background: linear-gradient(180deg, #2c3489 0%, #7b61ff 100%);
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
